<template>
  <div id="box">
    <canvas id="canvas">
      <p>your browser doesn't support canvas.</p>
    </canvas>
    <div id="stars"></div>
    <div id="stars2"></div>
    <div id="stars3"></div>
  </div>
</template>

<script>
export default {
  mounted() {
    // 线条运动函数
    // n参数：创建粒子的数量
    function line(n) {
      // 粒子特效
      var ParticleEffect = {
        ctx: null,
        canvas: null,
        particles: [],
        mouseCoordinates: {
          x: 0,
          y: 0,
        },
        config: {
          count: n, // 默认创建粒子数量
          radius: 4, // 默认粒子半径
          vxRange: [-1, 1], // 默认粒子横向移动速度范围
          vyRange: [-1, 1], // 默认粒子纵向移动速度范围
          scaleRange: [0.5, 1], // 默认粒子缩放比例范围
          lineLenThreshold: 125, // 默认连线长度阈值
          color: "rgba(255,255,255,.7)", // 默认粒子、线条的颜色
        },
        init: function (newConfig) {
          var _this = this;
          this.canvas = document.getElementById("canvas");
          this.ctx = this.canvas.getContext("2d");

          // 只有在浏览器支持canvas的情况下才有效
          if (this.ctx) {
            Utils.updateWindowSize();
            var windowSize = Utils.getWindowSize();

            // 设置canvas宽高
            this.canvas.width = windowSize.width;
            this.canvas.height = windowSize.height;

            // 更新config配置
            newConfig &&
              Object.keys(newConfig).forEach(function (key) {
                _this.config[key] = newConfig[key];
              });

            // 生成粒子
            var times = this.config.count;
            this.particles = [];
            while (times--) {
              this.particles.push(
                new Particle({
                  x: Utils.rangeRandom(
                    this.config.radius,
                    windowSize.width - this.config.radius
                  ),
                  y: Utils.rangeRandom(
                    this.config.radius,
                    windowSize.height - this.config.radius
                  ),
                  vx: Utils.rangeRandom(
                    this.config.vxRange[0],
                    this.config.vxRange[1]
                  ),
                  vy: Utils.rangeRandom(
                    this.config.vyRange[0],
                    this.config.vyRange[1]
                  ),
                  color: this.config.color,
                  scale: Utils.rangeRandom(
                    this.config.scaleRange[0],
                    this.config.scaleRange[1]
                  ),
                  radius: this.config.radius,
                })
              );
            }

            // 监听鼠标的mouseMove事件，记录下鼠标的x,y坐标
            window.addEventListener(
              "mousemove",
              this.handleMouseMove.bind(this),
              false
            );

            // 监听窗口大小改变事件
            window.addEventListener(
              "resize",
              this.handleWindowResize.bind(this),
              false
            );

            // 兼容requestAnimationFrame
            this.supportRequestAnimationFrame();
          }
        },
        move: function () {
          var windowSize = Utils.getWindowSize();

          this.particles.forEach(function (item) {
            // 更新粒子坐标
            item.x += item.vx;
            item.y += item.vy;

            // 如果粒子碰到了左墙壁或右墙壁，则改变粒子的横向运动方向
            if (
              item.x - item.radius < 0 ||
              item.x + item.radius > windowSize.width
            ) {
              item.vx *= -1;
            }

            // 如果粒子碰到了上墙壁或下墙壁，则改变粒子的纵向运动方向
            if (
              item.y - item.radius < 0 ||
              item.y + item.radius > windowSize.height
            ) {
              item.vy *= -1;
            }
          });
        },
        draw: function () {
          var _this = this;
          var lineLenThreshold = this.config.lineLenThreshold;
          var windowSize = Utils.getWindowSize();

          // 每次重新绘制之前，需要先清空画布，把上一次的内容清空
          this.ctx.clearRect(0, 0, windowSize.width, windowSize.height);

          // 绘制粒子
          this.particles.forEach(function (item) {
            item.draw(_this.ctx);
          });

          // 绘制粒子之间的连线
          for (var i = 0; i < this.particles.length; i++) {
            for (var j = i + 1; j < this.particles.length; j++) {
              var distance = Math.sqrt(
                Math.pow(this.particles[i].x - this.particles[j].x, 2) +
                  Math.pow(this.particles[i].y - this.particles[j].y, 2)
              );
              if (distance < lineLenThreshold) {
                // 这里我们让距离远的线透明度淡一点，距离近的线透明度深一点
                this.ctx.strokeStyle = this.translateColors(
                  this.config.color,
                  1 - distance / lineLenThreshold
                );
                this.ctx.beginPath();
                this.ctx.moveTo(this.particles[i].x, this.particles[i].y);
                this.ctx.lineTo(this.particles[j].x, this.particles[j].y);
                this.ctx.closePath();
                this.ctx.stroke();
              }
            }
          }

          // 绘制粒子和鼠标之间的连线
          for (i = 0; i < this.particles.length; i++) {
            distance = Math.sqrt(
              Math.pow(this.particles[i].x - this.mouseCoordinates.x, 2) +
                Math.pow(this.particles[i].y - this.mouseCoordinates.y, 2)
            );
            if (distance < lineLenThreshold) {
              this.ctx.strokeStyle = this.translateColors(
                this.config.color,
                1 - distance / lineLenThreshold
              );
              this.ctx.beginPath();
              this.ctx.moveTo(this.particles[i].x, this.particles[i].y);
              this.ctx.lineTo(this.mouseCoordinates.x, this.mouseCoordinates.y);
              this.ctx.closePath();
              this.ctx.stroke();
            }
          }

          // 粒子移动，更新相应的x, y坐标
          this.move();

          // 循环调用draw方法
          window.requestAnimationFrame(this.draw.bind(this));
        },
        handleMouseMove: function (event) {
          var x, y;
          event = event || window.event;

          if (event.pageX || event.pageY) {
            x = event.pageX;
            y = event.pageY;
          } else {
            x =
              event.clientX +
              document.body.scrollLeft +
              document.documentElement.scrollLeft;
            y =
              event.clientY +
              document.body.scrollTop +
              document.documentElement.scrollTop;
          }

          this.mouseCoordinates = {
            x: x,
            y: y,
          };
        },
        handleWindowResize: function () {
          Utils.updateWindowSize();
          var windowSize = Utils.getWindowSize();
          this.canvas.width = windowSize.width;
          this.canvas.height = windowSize.height;
        },
        translateColors: function (colorStr, ratio) {
          var r,
            g,
            b,
            a = 1,
            colorValues;

          if (colorStr[0] === "#") {
            // 传的是#RRGGBB形式
            r = parseInt(colorStr.slice(1, 3), 16);
            g = parseInt(colorStr.slice(3, 5), 16);
            b = parseInt(colorStr.slice(5, 7), 16);
          } else if (colorStr.startsWith("rgb(")) {
            // 传的是rgb(r,g,b)形式
            colorStr = colorStr.slice(4, colorStr.length - 1);
            colorValues = colorStr.split(",");
            r = parseInt(colorValues[0].trim());
            g = parseInt(colorValues[1].trim());
            b = parseInt(colorValues[2].trim());
          } else if (colorStr.startsWith("rgba(")) {
            // 传的是rgba(r,g,b,a)形式
            colorStr = colorStr.slice(5, colorStr.length - 1);
            colorValues = colorStr.split(",");
            r = parseInt(colorValues[0].trim());
            g = parseInt(colorValues[1].trim());
            b = parseInt(colorValues[2].trim());
            a = parseFloat(colorValues[3].trim());
          }

          return "rgba(" + r + "," + g + "," + b + "," + a * ratio + ")";
        },
        supportRequestAnimationFrame: function () {
          if (!window.requestAnimationFrame) {
            window.requestAnimationFrame =
              window.webkitRequestAnimationFrame ||
              window.mozRequestAnimationFrame ||
              window.oRequestAnimationFrame ||
              window.msRequestAnimationFrame ||
              function (callback) {
                setInterval(callback, 1000 / 60);
              };
          }
        },
        run: function (config) {
          this.init(config);
          window.requestAnimationFrame(this.draw.bind(this));
        },
      };

      /**
       * Particle 粒子类
       */
      function Particle(attr) {
        // 粒子属性
        this.x = attr.x; // 粒子在画布中的横坐标
        this.y = attr.y; // 粒子在画布中的纵坐标
        this.vx = attr.vx; // 粒子的横向运动速度
        this.vy = attr.vy; // 粒子的纵向运动速度
        this.color = attr.color; // 粒子的颜色
        this.scale = attr.scale; // 粒子的缩放比例
        this.radius = attr.radius; // 粒子的半径大小

        // 绘制方法
        if (typeof Particle.prototype.draw === "undefined") {
          Particle.prototype.draw = function (ctx) {
            // canvas画圆方法
            ctx.beginPath();
            ctx.fillStyle = this.color;
            ctx.strokeStyle = this.color;
            ctx.arc(
              this.x,
              this.y,
              this.radius * this.scale,
              0,
              2 * Math.PI,
              false
            );
            ctx.closePath();
            ctx.fill();
          };
        }
      }

      // 工具
      var Utils = {
        _windowSize: {
          width: 0,
          height: 0,
        },
        getWindowSize: function () {
          return this._windowSize;
        },
        updateWindowSize: function () {
          this._windowSize.width = this.getWindowWidth();
          this._windowSize.height = this.getWindowHeight();
        },
        getWindowWidth: function () {
          return window.innerWidth || document.documentElement.clientWidth;
        },
        getWindowHeight: function () {
          return window.innerHeight || document.documentElement.clientHeight;
        },
        rangeRandom: function (min, max) {
          const diff = max - min;
          return min + Math.random() * diff;
        },
      };

      ParticleEffect.run({
        count: 30,
      });
    }

    line(20);
    // // 获取div标签对象
    var oDiv = document.getElementById("box");
    // 视窗窗口监听事件
    // 当 视窗窗口 宽度高度改变时 触发函数程序
    window.addEventListener("resize", function () {
      // 视窗窗口 宽度高度改变时 触发函数程序
      // 获取 浏览器视窗窗口的宽度
      var width = document.body.offsetWidth;
      // console.log(width);

      // 如果 视窗窗口宽度 大于 1200 div的宽度是原始宽度的100%
      if (width > 1100) {
        line(20);

        // 如果 视窗窗口宽度 在 1200 至 960 div的宽度是原始宽度的 80%
      } else if (width <= 1100 && width > 800) {
        line(15);

        // 如果 视窗窗口宽度 在 960 至 768 div的宽度是原始宽度的 60%
      } else if (width <= 800 && width > 500) {
        line(10);

        // 如果 视窗窗口宽度 小于 768 div的宽度是原始宽度的 40%
      } else if (width <= 500) {
        line(5);
      }
    });
  },
};
</script>

<style scoped>
#box {
  height: 100vh;
  position: absolute;
  width: 100%;
  overflow: hidden;
}

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 2516px 1519px #fff, 2408px 216px #fff, 2366px 329px #fff,
    2019px 27px #fff, 2310px 381px #fff, 2416px 489px #fff, 2483px 370px #fff,
    2342px 1969px #fff, 2369px 1948px #fff, 2168px 1386px #fff,
    2293px 1859px #fff, 2386px 1010px #fff, 2156px 1601px #fff,
    2489px 936px #fff, 2304px 1249px #fff, 2500px 39px #fff, 2100px 767px #fff,
    2208px 1086px #fff, 2109px 918px #fff, 2105px 1077px #fff,
    2000px 1532px #fff, 2050px 400px #fff, 2150px 705px #fff, 2308px 1122px #fff,
    2400px 108px #fff, 2560px 609px #fff, 2200px 27px #fff, 2200px 27px #fff,
    2200px 27px #fff, 2200px 27px #fff, 2200px 27px #fff, 2200px 27px #fff,
    2000px 58px #fff, 2200px 1120px #fff, 2000px 560px #fff, 1861px 1311px #fff,
    755px 1164px #fff, 1819px 1636px #fff, 79px 1990px #fff, 1634px 1830px #fff,
    1991px 1593px #fff, 842px 1082px #fff, 510px 357px #fff, 832px 1243px #fff,
    1932px 89px #fff, 1866px 538px #fff, 1590px 993px #fff, 884px 374px #fff,
    471px 1350px #fff, 67px 542px #fff, 296px 115px #fff, 424px 816px #fff,
    1179px 1854px #fff, 459px 420px #fff, 833px 813px #fff, 1080px 1562px #fff,
    205px 507px #fff, 916px 262px #fff, 391px 814px #fff, 1692px 812px #fff,
    1576px 560px #fff, 1717px 1095px #fff, 552px 1075px #fff, 568px 1045px #fff,
    591px 106px #fff, 1236px 359px #fff, 1409px 309px #fff, 1928px 1000px #fff,
    918px 158px #fff, 1010px 667px #fff, 752px 559px #fff, 813px 164px #fff,
    298px 1688px #fff, 1382px 34px #fff, 470px 145px #fff, 1740px 1571px #fff,
    1418px 8px #fff, 1254px 220px #fff, 1686px 830px #fff, 1968px 426px #fff,
    973px 1491px #fff, 1516px 771px #fff, 1874px 957px #fff, 1557px 108px #fff,
    1640px 424px #fff, 1149px 881px #fff, 287px 984px #fff, 1950px 3px #fff,
    1082px 751px #fff, 1417px 1902px #fff, 560px 1474px #fff, 1926px 628px #fff,
    1718px 1699px #fff, 1996px 355px #fff, 1877px 703px #fff, 634px 1201px #fff,
    87px 819px #fff, 114px 134px #fff, 1291px 614px #fff, 703px 1142px #fff,
    597px 1767px #fff, 223px 1715px #fff, 126px 14px #fff, 1091px 737px #fff,
    1614px 700px #fff, 1631px 960px #fff, 1666px 1856px #fff, 1645px 764px #fff,
    354px 240px #fff, 1445px 713px #fff, 137px 793px #fff, 1273px 267px #fff,
    642px 33px #fff, 1687px 508px #fff, 1713px 1877px #fff, 430px 700px #fff,
    1974px 1403px #fff, 1653px 483px #fff, 1372px 447px #fff, 1165px 1330px #fff,
    415px 1726px #fff, 1532px 95px #fff, 116px 309px #fff, 1669px 60px #fff,
    101px 251px #fff, 1111px 231px #fff, 1784px 1832px #fff, 1158px 239px #fff,
    343px 1491px #fff, 257px 1665px #fff, 1434px 655px #fff, 547px 1951px #fff,
    1351px 899px #fff, 1533px 1134px #fff, 666px 524px #fff, 835px 661px #fff,
    759px 1468px #fff, 1286px 1184px #fff, 1024px 314px #fff, 233px 305px #fff,
    547px 1114px #fff, 873px 1192px #fff, 1386px 1143px #fff, 22px 1304px #fff,
    1503px 437px #fff, 1724px 77px #fff, 1795px 1518px #fff, 730px 1023px #fff,
    1197px 1446px #fff, 59px 1342px #fff, 352px 523px #fff, 1437px 1068px #fff,
    1972px 177px #fff, 1961px 1489px #fff, 471px 645px #fff, 903px 904px #fff,
    1896px 1822px #fff, 426px 157px #fff, 1882px 1660px #fff, 1252px 1438px #fff,
    1506px 113px #fff, 1206px 1936px #fff, 1370px 1946px #fff, 179px 1583px #fff,
    1665px 1736px #fff, 1791px 1111px #fff, 717px 1776px #fff, 540px 1607px #fff,
    1610px 994px #fff, 960px 1246px #fff, 1953px 343px #fff, 1730px 1578px #fff,
    708px 610px #fff, 290px 1562px #fff, 1352px 251px #fff, 132px 153px #fff,
    720px 1590px #fff, 1004px 1159px #fff, 1583px 1797px #fff,
    1263px 1625px #fff, 1759px 537px #fff, 10px 5px #fff, 141px 1228px #fff,
    434px 1422px #fff, 705px 438px #fff, 349px 1452px #fff, 953px 33px #fff,
    806px 600px #fff, 1657px 188px #fff, 1731px 888px #fff, 1958px 795px #fff,
    75px 2000px #fff, 35px 1974px #fff, 1957px 1445px #fff, 1708px 1083px #fff,
    220px 1310px #fff, 1188px 849px #fff, 225px 445px #fff, 1946px 1631px #fff,
    926px 812px #fff, 366px 382px #fff, 603px 258px #fff, 1542px 361px #fff,
    988px 702px #fff, 27px 917px #fff, 571px 542px #fff, 1513px 904px #fff,
    1909px 471px #fff, 1073px 617px #fff, 1434px 589px #fff, 1703px 471px #fff,
    1143px 1678px #fff, 1556px 1706px #fff, 971px 1109px #fff, 1642px 302px #fff,
    969px 129px #fff, 363px 1172px #fff, 1330px 27px #fff, 520px 210px #fff,
    1919px 1349px #fff, 1449px 500px #fff, 313px 1231px #fff, 1936px 250px #fff,
    907px 1244px #fff, 1873px 953px #fff, 255px 408px #fff, 1492px 387px #fff,
    760px 206px #fff, 1868px 938px #fff, 1724px 1582px #fff, 1152px 1470px #fff,
    89px 1245px #fff, 1747px 1336px #fff, 198px 326px #fff, 832px 558px #fff,
    1441px 967px #fff, 821px 1611px #fff, 551px 1173px #fff, 1130px 179px #fff,
    1699px 56px #fff, 1064px 1686px #fff, 37px 431px #fff, 1051px 1665px #fff,
    1736px 1736px #fff, 1877px 1941px #fff, 615px 595px #fff, 1224px 1566px #fff,
    150px 437px #fff, 887px 430px #fff, 1661px 1266px #fff, 1232px 1720px #fff,
    4px 1198px #fff, 28px 1730px #fff, 1634px 527px #fff, 485px 176px #fff,
    1832px 107px #fff, 403px 1461px #fff, 821px 1106px #fff, 906px 408px #fff,
    49px 1918px #fff, 938px 181px #fff, 1534px 993px #fff, 143px 822px #fff,
    919px 1666px #fff, 156px 1057px #fff, 1949px 65px #fff, 1211px 1421px #fff,
    1596px 852px #fff, 370px 1102px #fff, 859px 1233px #fff, 1061px 1583px #fff,
    1843px 1242px #fff, 1166px 848px #fff, 1407px 798px #fff, 1681px 1033px #fff,
    564px 16px #fff, 1879px 1422px #fff, 1809px 1914px #fff, 1837px 53px #fff,
    1008px 318px #fff, 1267px 1262px #fff, 384px 402px #fff, 494px 1974px #fff,
    490px 1572px #fff, 1358px 643px #fff, 227px 1580px #fff, 969px 865px #fff,
    705px 649px #fff, 1122px 1572px #fff, 1819px 894px #fff, 624px 1242px #fff,
    1784px 1172px #fff, 95px 68px #fff, 1504px 872px #fff, 980px 1114px #fff,
    1766px 1256px #fff, 86px 273px #fff, 1271px 120px #fff, 1482px 1685px #fff,
    1319px 1523px #fff, 898px 1935px #fff, 1518px 1201px #fff, 474px 378px #fff,
    686px 1878px #fff, 1831px 108px #fff, 1031px 821px #fff, 835px 839px #fff,
    550px 601px #fff, 617px 287px #fff, 859px 1924px #fff, 392px 1044px #fff,
    438px 1621px #fff, 697px 1488px #fff, 1463px 52px #fff, 638px 1106px #fff,
    1888px 1331px #fff, 1574px 281px #fff, 1639px 451px #fff, 371px 584px #fff,
    1057px 385px #fff, 1097px 222px #fff, 581px 507px #fff, 328px 1240px #fff,
    1751px 1628px #fff, 865px 361px #fff, 861px 1545px #fff, 254px 1019px #fff,
    411px 1413px #fff, 1789px 916px #fff, 1736px 1044px #fff, 122px 713px #fff,
    1737px 679px #fff, 907px 477px #fff, 1138px 820px #fff, 255px 1489px #fff,
    794px 983px #fff, 289px 1842px #fff, 1794px 67px #fff, 878px 679px #fff,
    964px 1076px #fff, 643px 174px #fff, 1728px 139px #fff, 547px 226px #fff,
    914px 1508px #fff, 936px 1003px #fff, 1049px 661px #fff, 1856px 1677px #fff,
    1407px 1549px #fff, 1860px 1291px #fff, 1599px 1243px #fff,
    733px 1683px #fff, 473px 1980px #fff, 691px 811px #fff, 835px 1103px #fff,
    1164px 404px #fff, 538px 1684px #fff, 1858px 1372px #fff, 307px 488px #fff,
    1758px 579px #fff, 1844px 1480px #fff, 945px 1725px #fff, 203px 1451px #fff,
    752px 279px #fff, 640px 94px #fff, 1615px 828px #fff, 322px 1472px #fff,
    1557px 953px #fff, 1356px 973px #fff, 1194px 297px #fff, 1303px 557px #fff,
    309px 1338px #fff, 1247px 1425px #fff, 239px 670px #fff, 328px 806px #fff,
    747px 276px #fff, 1989px 515px #fff, 850px 1475px #fff, 698px 97px #fff,
    449px 1353px #fff, 1410px 1025px #fff, 1999px 835px #fff, 1285px 1609px #fff,
    97px 900px #fff, 526px 496px #fff, 412px 1248px #fff, 1683px 1238px #fff,
    1315px 643px #fff, 1315px 802px #fff, 444px 88px #fff, 1803px 1998px #fff,
    105px 6px #fff, 1663px 1339px #fff, 1430px 1388px #fff, 1653px 188px #fff,
    360px 542px #fff, 687px 1983px #fff, 538px 1114px #fff, 76px 969px #fff,
    1004px 1763px #fff, 482px 166px #fff, 1071px 547px #fff, 1495px 1787px #fff,
    863px 346px #fff, 917px 727px #fff, 1523px 415px #fff, 411px 699px #fff,
    1570px 386px #fff, 1074px 526px #fff, 931px 45px #fff, 1409px 1493px #fff,
    278px 507px #fff, 715px 39px #fff, 100px 1507px #fff, 350px 1568px #fff,
    1711px 340px #fff, 1562px 705px #fff, 1438px 1017px #fff, 100px 727px #fff,
    128px 784px #fff, 1605px 1926px #fff, 1451px 77px #fff, 1824px 1666px #fff,
    653px 1612px #fff, 970px 159px #fff, 520px 1182px #fff, 1849px 488px #fff,
    385px 927px #fff, 557px 491px #fff, 1302px 1776px #fff, 1211px 160px #fff,
    325px 1566px #fff, 1997px 292px #fff, 1582px 436px #fff, 894px 1002px #fff,
    1509px 909px #fff, 1364px 1670px #fff, 701px 1903px #fff, 392px 1293px #fff,
    1018px 1320px #fff, 621px 87px #fff, 1069px 578px #fff, 611px 1765px #fff,
    1327px 1460px #fff, 1224px 1447px #fff, 1506px 1796px #fff, 152px 933px #fff,
    188px 875px #fff, 1766px 540px #fff, 768px 1824px #fff, 599px 1853px #fff,
    98px 1109px #fff, 1470px 345px #fff, 150px 856px #fff, 373px 1276px #fff,
    1692px 268px #fff, 725px 1647px #fff, 1948px 1844px #fff, 882px 643px #fff,
    536px 1541px #fff, 158px 681px #fff, 713px 1757px #fff, 227px 489px #fff,
    108px 1985px #fff, 11px 1909px #fff, 944px 1086px #fff, 1048px 983px #fff,
    1561px 1515px #fff, 537px 288px #fff, 1656px 385px #fff, 144px 748px #fff,
    948px 1924px #fff, 673px 1057px #fff, 1369px 1905px #fff, 775px 1833px #fff,
    588px 1156px #fff, 1832px 40px #fff, 463px 1967px #fff, 1097px 282px #fff,
    190px 381px #fff, 347px 288px #fff, 1313px 1638px #fff, 1212px 1594px #fff,
    1623px 326px #fff, 614px 1494px #fff, 652px 302px #fff, 72px 1540px #fff,
    1040px 1669px #fff, 1888px 1901px #fff, 1272px 982px #fff, 725px 991px #fff,
    1045px 1747px #fff, 1719px 4px #fff, 1943px 601px #fff, 1054px 1374px #fff,
    627px 1995px #fff, 1912px 859px #fff, 1068px 306px #fff, 584px 1355px #fff,
    397px 1681px #fff, 643px 740px #fff, 1592px 1529px #fff, 358px 1683px #fff,
    1680px 441px #fff, 500px 1836px #fff, 1277px 442px #fff, 946px 1630px #fff,
    1969px 496px #fff, 1338px 642px #fff, 319px 1161px #fff, 989px 1007px #fff,
    1292px 1996px #fff, 1207px 1767px #fff, 1468px 1761px #fff,
    1749px 1306px #fff, 111px 163px #fff, 1003px 626px #fff, 393px 1234px #fff,
    881px 113px #fff, 736px 1106px #fff, 659px 303px #fff, 705px 501px #fff,
    1735px 1560px #fff, 1241px 1067px #fff, 1521px 676px #fff, 147px 563px #fff,
    810px 1879px #fff, 1469px 1129px #fff, 1184px 950px #fff, 1768px 1919px #fff,
    610px 1366px #fff, 1779px 1742px #fff, 736px 1576px #fff, 539px 318px #fff,
    929px 762px #fff, 1253px 1195px #fff, 144px 1829px #fff, 252px 705px #fff,
    1436px 1522px #fff, 1884px 32px #fff, 901px 1643px #fff, 559px 1566px #fff,
    1749px 68px #fff, 749px 173px #fff, 883px 240px #fff, 442px 1246px #fff,
    1358px 1567px #fff, 729px 1304px #fff, 1923px 1934px #fff,
    1400px 1395px #fff, 574px 833px #fff, 1847px 750px #fff, 1817px 1688px #fff,
    1232px 790px #fff, 1819px 1426px #fff, 1136px 149px #fff, 1650px 1073px #fff,
    1450px 149px #fff, 490px 568px #fff, 1107px 1481px #fff, 394px 1666px #fff,
    75px 708px #fff, 1821px 794px #fff, 1401px 641px #fff, 1997px 1172px #fff,
    1574px 932px #fff, 135px 288px #fff, 1372px 1073px #fff, 903px 1px #fff,
    1616px 1191px #fff, 1266px 31px #fff, 1615px 1893px #fff, 234px 765px #fff,
    689px 137px #fff, 784px 1463px #fff, 735px 123px #fff, 1558px 1184px #fff,
    916px 483px #fff, 1710px 1668px #fff, 733px 1936px #fff, 533px 9px #fff,
    253px 1376px #fff, 1648px 686px #fff, 700px 1563px #fff, 3px 943px #fff,
    333px 165px #fff, 133px 509px #fff, 1764px 404px #fff, 996px 915px #fff,
    594px 1363px #fff, 75px 726px #fff, 1110px 1967px #fff, 1428px 1817px #fff,
    187px 1685px #fff, 465px 304px #fff, 1976px 103px #fff, 1308px 887px #fff,
    770px 1040px #fff, 752px 429px #fff, 267px 35px #fff, 274px 1252px #fff,
    115px 1035px #fff, 539px 1178px #fff, 286px 741px #fff, 1291px 289px #fff,
    1988px 1717px #fff, 993px 1296px #fff, 519px 358px #fff, 239px 838px #fff,
    1095px 842px #fff, 506px 1961px #fff, 1355px 1885px #fff, 970px 1463px #fff,
    399px 1390px #fff, 188px 1622px #fff, 1685px 1321px #fff, 1754px 588px #fff,
    1622px 720px #fff, 1709px 1781px #fff, 1564px 510px #fff, 1591px 240px #fff,
    1643px 1204px #fff, 1243px 1487px #fff, 99px 1417px #fff, 467px 520px #fff,
    1351px 936px #fff, 1523px 1653px #fff, 1173px 3px #fff, 310px 868px #fff,
    1451px 1094px #fff, 191px 803px #fff, 1463px 1971px #fff, 1098px 303px #fff,
    1589px 219px #fff, 1947px 1288px #fff, 1167px 1131px #fff,
    1787px 1276px #fff, 1833px 1665px #fff, 508px 567px #fff, 1617px 1096px #fff,
    960px 1163px #fff, 1126px 1199px #fff, 838px 568px #fff, 2px 1861px #fff,
    873px 799px #fff, 1337px 1535px #fff, 1967px 482px #fff, 69px 1412px #fff,
    436px 697px #fff, 590px 1332px #fff, 1996px 1720px #fff, 732px 1205px #fff,
    1669px 1027px #fff, 1700px 1309px #fff, 877px 1777px #fff, 1368px 393px #fff,
    1911px 1550px #fff, 985px 1343px #fff, 1340px 559px #fff, 229px 1121px #fff,
    269px 1500px #fff, 1285px 1231px #fff, 896px 1259px #fff, 1156px 1979px #fff,
    920px 1531px #fff, 762px 456px #fff, 1953px 1705px #fff, 740px 1945px #fff,
    1155px 1482px #fff, 1571px 1712px #fff, 670px 984px #fff, 843px 1218px #fff,
    1885px 1231px #fff, 275px 1887px #fff, 1993px 1734px #fff, 1752px 871px #fff,
    1812px 1788px #fff, 126px 1661px #fff, 1733px 590px #fff, 1787px 516px #fff,
    1908px 260px #fff, 500px 308px #fff, 1249px 1432px #fff, 1073px 38px #fff,
    694px 519px #fff, 1435px 293px #fff, 1376px 185px #fff, 879px 1371px #fff,
    1665px 1752px #fff, 500px 355px #fff, 905px 65px #fff, 106px 1505px #fff,
    242px 604px #fff, 277px 1533px #fff, 78px 837px #fff, 41px 1912px #fff,
    1134px 548px #fff, 1725px 1225px #fff, 1227px 750px #fff, 671px 1213px #fff,
    1828px 506px #fff, 932px 1181px #fff, 1271px 1159px #fff, 1048px 591px #fff,
    1333px 497px #fff, 573px 1472px #fff, 1890px 28px #fff, 573px 461px #fff,
    1141px 839px #fff, 1598px 1433px #fff, 406px 1747px #fff, 1716px 201px #fff,
    1406px 1901px #fff, 160px 150px #fff, 1140px 373px #fff, 1719px 921px #fff,
    237px 1014px #fff, 325px 256px #fff, 398px 675px #fff, 1487px 1489px #fff,
    1971px 709px #fff, 1134px 1120px #fff, 1395px 263px #fff, 1251px 1304px #fff,
    917px 1037px #fff, 1998px 743px #fff, 1615px 1334px #fff, 1512px 871px #fff,
    57px 673px #fff, 1089px 15px #fff, 1657px 1868px #fff, 974px 1904px #fff,
    1719px 1780px #fff, 265px 767px #fff, 1168px 961px #fff, 253px 228px #fff;
  -webkit-animation: animStar 300s linear infinite;
  animation: animStar 300s linear infinite;
}
#stars:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 2416px 1519px #fff, 2508px 216px #fff, 2266px 329px #fff,
    2099px 27px #fff, 2340px 381px #fff, 2316px 489px #fff, 2493px 370px #fff,
    2300px 1969px #fff, 2050px 400px #fff, 2150px 705px #fff, 2308px 1122px #fff,
    2400px 108px #fff, 2560px 609px #fff, 2200px 27px #fff, 2000px 58px #fff,
    2200px 1120px #fff, 2000px 560px #fff, 2200px 1311px #fff,
    1861px 1311px #fff, 755px 1164px #fff, 1819px 1636px #fff, 79px 1990px #fff,
    1634px 1830px #fff, 1991px 1593px #fff, 842px 1082px #fff, 510px 357px #fff,
    832px 1243px #fff, 1932px 89px #fff, 1866px 538px #fff, 1590px 993px #fff,
    884px 374px #fff, 471px 1350px #fff, 67px 542px #fff, 296px 115px #fff,
    424px 816px #fff, 1179px 1854px #fff, 459px 420px #fff, 833px 813px #fff,
    1080px 1562px #fff, 205px 507px #fff, 916px 262px #fff, 391px 814px #fff,
    1692px 812px #fff, 1576px 560px #fff, 1717px 1095px #fff, 552px 1075px #fff,
    568px 1045px #fff, 591px 106px #fff, 1236px 359px #fff, 1409px 309px #fff,
    1928px 1000px #fff, 918px 158px #fff, 1010px 667px #fff, 752px 559px #fff,
    813px 164px #fff, 298px 1688px #fff, 1382px 34px #fff, 470px 145px #fff,
    1740px 1571px #fff, 1418px 8px #fff, 1254px 220px #fff, 1686px 830px #fff,
    1968px 426px #fff, 973px 1491px #fff, 1516px 771px #fff, 1874px 957px #fff,
    1557px 108px #fff, 1640px 424px #fff, 1149px 881px #fff, 287px 984px #fff,
    1950px 3px #fff, 1082px 751px #fff, 1417px 1902px #fff, 560px 1474px #fff,
    1926px 628px #fff, 1718px 1699px #fff, 1996px 355px #fff, 1877px 703px #fff,
    634px 1201px #fff, 87px 819px #fff, 114px 134px #fff, 1291px 614px #fff,
    703px 1142px #fff, 597px 1767px #fff, 223px 1715px #fff, 126px 14px #fff,
    1091px 737px #fff, 1614px 700px #fff, 1631px 960px #fff, 1666px 1856px #fff,
    1645px 764px #fff, 354px 240px #fff, 1445px 713px #fff, 137px 793px #fff,
    1273px 267px #fff, 642px 33px #fff, 1687px 508px #fff, 1713px 1877px #fff,
    430px 700px #fff, 1974px 1403px #fff, 1653px 483px #fff, 1372px 447px #fff,
    1165px 1330px #fff, 415px 1726px #fff, 1532px 95px #fff, 116px 309px #fff,
    1669px 60px #fff, 101px 251px #fff, 1111px 231px #fff, 1784px 1832px #fff,
    1158px 239px #fff, 343px 1491px #fff, 257px 1665px #fff, 1434px 655px #fff,
    547px 1951px #fff, 1351px 899px #fff, 1533px 1134px #fff, 666px 524px #fff,
    835px 661px #fff, 759px 1468px #fff, 1286px 1184px #fff, 1024px 314px #fff,
    233px 305px #fff, 547px 1114px #fff, 873px 1192px #fff, 1386px 1143px #fff,
    22px 1304px #fff, 1503px 437px #fff, 1724px 77px #fff, 1795px 1518px #fff,
    730px 1023px #fff, 1197px 1446px #fff, 59px 1342px #fff, 352px 523px #fff,
    1437px 1068px #fff, 1972px 177px #fff, 1961px 1489px #fff, 471px 645px #fff,
    903px 904px #fff, 1896px 1822px #fff, 426px 157px #fff, 1882px 1660px #fff,
    1252px 1438px #fff, 1506px 113px #fff, 1206px 1936px #fff,
    1370px 1946px #fff, 179px 1583px #fff, 1665px 1736px #fff,
    1791px 1111px #fff, 717px 1776px #fff, 540px 1607px #fff, 1610px 994px #fff,
    960px 1246px #fff, 1953px 343px #fff, 1730px 1578px #fff, 708px 610px #fff,
    290px 1562px #fff, 1352px 251px #fff, 132px 153px #fff, 720px 1590px #fff,
    1004px 1159px #fff, 1583px 1797px #fff, 1263px 1625px #fff,
    1759px 537px #fff, 10px 5px #fff, 141px 1228px #fff, 434px 1422px #fff,
    705px 438px #fff, 349px 1452px #fff, 953px 33px #fff, 806px 600px #fff,
    1657px 188px #fff, 1731px 888px #fff, 1958px 795px #fff, 75px 2000px #fff,
    35px 1974px #fff, 1957px 1445px #fff, 1708px 1083px #fff, 220px 1310px #fff,
    1188px 849px #fff, 225px 445px #fff, 1946px 1631px #fff, 926px 812px #fff,
    366px 382px #fff, 603px 258px #fff, 1542px 361px #fff, 988px 702px #fff,
    27px 917px #fff, 571px 542px #fff, 1513px 904px #fff, 1909px 471px #fff,
    1073px 617px #fff, 1434px 589px #fff, 1703px 471px #fff, 1143px 1678px #fff,
    1556px 1706px #fff, 971px 1109px #fff, 1642px 302px #fff, 969px 129px #fff,
    363px 1172px #fff, 1330px 27px #fff, 520px 210px #fff, 1919px 1349px #fff,
    1449px 500px #fff, 313px 1231px #fff, 1936px 250px #fff, 907px 1244px #fff,
    1873px 953px #fff, 255px 408px #fff, 1492px 387px #fff, 760px 206px #fff,
    1868px 938px #fff, 1724px 1582px #fff, 1152px 1470px #fff, 89px 1245px #fff,
    1747px 1336px #fff, 198px 326px #fff, 832px 558px #fff, 1441px 967px #fff,
    821px 1611px #fff, 551px 1173px #fff, 1130px 179px #fff, 1699px 56px #fff,
    1064px 1686px #fff, 37px 431px #fff, 1051px 1665px #fff, 1736px 1736px #fff,
    1877px 1941px #fff, 615px 595px #fff, 1224px 1566px #fff, 150px 437px #fff,
    887px 430px #fff, 1661px 1266px #fff, 1232px 1720px #fff, 4px 1198px #fff,
    28px 1730px #fff, 1634px 527px #fff, 485px 176px #fff, 1832px 107px #fff,
    403px 1461px #fff, 821px 1106px #fff, 906px 408px #fff, 49px 1918px #fff,
    938px 181px #fff, 1534px 993px #fff, 143px 822px #fff, 919px 1666px #fff,
    156px 1057px #fff, 1949px 65px #fff, 1211px 1421px #fff, 1596px 852px #fff,
    370px 1102px #fff, 859px 1233px #fff, 1061px 1583px #fff, 1843px 1242px #fff,
    1166px 848px #fff, 1407px 798px #fff, 1681px 1033px #fff, 564px 16px #fff,
    1879px 1422px #fff, 1809px 1914px #fff, 1837px 53px #fff, 1008px 318px #fff,
    1267px 1262px #fff, 384px 402px #fff, 494px 1974px #fff, 490px 1572px #fff,
    1358px 643px #fff, 227px 1580px #fff, 969px 865px #fff, 705px 649px #fff,
    1122px 1572px #fff, 1819px 894px #fff, 624px 1242px #fff, 1784px 1172px #fff,
    95px 68px #fff, 1504px 872px #fff, 980px 1114px #fff, 1766px 1256px #fff,
    86px 273px #fff, 1271px 120px #fff, 1482px 1685px #fff, 1319px 1523px #fff,
    898px 1935px #fff, 1518px 1201px #fff, 474px 378px #fff, 686px 1878px #fff,
    1831px 108px #fff, 1031px 821px #fff, 835px 839px #fff, 550px 601px #fff,
    617px 287px #fff, 859px 1924px #fff, 392px 1044px #fff, 438px 1621px #fff,
    697px 1488px #fff, 1463px 52px #fff, 638px 1106px #fff, 1888px 1331px #fff,
    1574px 281px #fff, 1639px 451px #fff, 371px 584px #fff, 1057px 385px #fff,
    1097px 222px #fff, 581px 507px #fff, 328px 1240px #fff, 1751px 1628px #fff,
    865px 361px #fff, 861px 1545px #fff, 254px 1019px #fff, 411px 1413px #fff,
    1789px 916px #fff, 1736px 1044px #fff, 122px 713px #fff, 1737px 679px #fff,
    907px 477px #fff, 1138px 820px #fff, 255px 1489px #fff, 794px 983px #fff,
    289px 1842px #fff, 1794px 67px #fff, 878px 679px #fff, 964px 1076px #fff,
    643px 174px #fff, 1728px 139px #fff, 547px 226px #fff, 914px 1508px #fff,
    936px 1003px #fff, 1049px 661px #fff, 1856px 1677px #fff, 1407px 1549px #fff,
    1860px 1291px #fff, 1599px 1243px #fff, 733px 1683px #fff, 473px 1980px #fff,
    691px 811px #fff, 835px 1103px #fff, 1164px 404px #fff, 538px 1684px #fff,
    1858px 1372px #fff, 307px 488px #fff, 1758px 579px #fff, 1844px 1480px #fff,
    945px 1725px #fff, 203px 1451px #fff, 752px 279px #fff, 640px 94px #fff,
    1615px 828px #fff, 322px 1472px #fff, 1557px 953px #fff, 1356px 973px #fff,
    1194px 297px #fff, 1303px 557px #fff, 309px 1338px #fff, 1247px 1425px #fff,
    239px 670px #fff, 328px 806px #fff, 747px 276px #fff, 1989px 515px #fff,
    850px 1475px #fff, 698px 97px #fff, 449px 1353px #fff, 1410px 1025px #fff,
    1999px 835px #fff, 1285px 1609px #fff, 97px 900px #fff, 526px 496px #fff,
    412px 1248px #fff, 1683px 1238px #fff, 1315px 643px #fff, 1315px 802px #fff,
    444px 88px #fff, 1803px 1998px #fff, 105px 6px #fff, 1663px 1339px #fff,
    1430px 1388px #fff, 1653px 188px #fff, 360px 542px #fff, 687px 1983px #fff,
    538px 1114px #fff, 76px 969px #fff, 1004px 1763px #fff, 482px 166px #fff,
    1071px 547px #fff, 1495px 1787px #fff, 863px 346px #fff, 917px 727px #fff,
    1523px 415px #fff, 411px 699px #fff, 1570px 386px #fff, 1074px 526px #fff,
    931px 45px #fff, 1409px 1493px #fff, 278px 507px #fff, 715px 39px #fff,
    100px 1507px #fff, 350px 1568px #fff, 1711px 340px #fff, 1562px 705px #fff,
    1438px 1017px #fff, 100px 727px #fff, 128px 784px #fff, 1605px 1926px #fff,
    1451px 77px #fff, 1824px 1666px #fff, 653px 1612px #fff, 970px 159px #fff,
    520px 1182px #fff, 1849px 488px #fff, 385px 927px #fff, 557px 491px #fff,
    1302px 1776px #fff, 1211px 160px #fff, 325px 1566px #fff, 1997px 292px #fff,
    1582px 436px #fff, 894px 1002px #fff, 1509px 909px #fff, 1364px 1670px #fff,
    701px 1903px #fff, 392px 1293px #fff, 1018px 1320px #fff, 621px 87px #fff,
    1069px 578px #fff, 611px 1765px #fff, 1327px 1460px #fff, 1224px 1447px #fff,
    1506px 1796px #fff, 152px 933px #fff, 188px 875px #fff, 1766px 540px #fff,
    768px 1824px #fff, 599px 1853px #fff, 98px 1109px #fff, 1470px 345px #fff,
    150px 856px #fff, 373px 1276px #fff, 1692px 268px #fff, 725px 1647px #fff,
    1948px 1844px #fff, 882px 643px #fff, 536px 1541px #fff, 158px 681px #fff,
    713px 1757px #fff, 227px 489px #fff, 108px 1985px #fff, 11px 1909px #fff,
    944px 1086px #fff, 1048px 983px #fff, 1561px 1515px #fff, 537px 288px #fff,
    1656px 385px #fff, 144px 748px #fff, 948px 1924px #fff, 673px 1057px #fff,
    1369px 1905px #fff, 775px 1833px #fff, 588px 1156px #fff, 1832px 40px #fff,
    463px 1967px #fff, 1097px 282px #fff, 190px 381px #fff, 347px 288px #fff,
    1313px 1638px #fff, 1212px 1594px #fff, 1623px 326px #fff, 614px 1494px #fff,
    652px 302px #fff, 72px 1540px #fff, 1040px 1669px #fff, 1888px 1901px #fff,
    1272px 982px #fff, 725px 991px #fff, 1045px 1747px #fff, 1719px 4px #fff,
    1943px 601px #fff, 1054px 1374px #fff, 627px 1995px #fff, 1912px 859px #fff,
    1068px 306px #fff, 584px 1355px #fff, 397px 1681px #fff, 643px 740px #fff,
    1592px 1529px #fff, 358px 1683px #fff, 1680px 441px #fff, 500px 1836px #fff,
    1277px 442px #fff, 946px 1630px #fff, 1969px 496px #fff, 1338px 642px #fff,
    319px 1161px #fff, 989px 1007px #fff, 1292px 1996px #fff, 1207px 1767px #fff,
    1468px 1761px #fff, 1749px 1306px #fff, 111px 163px #fff, 1003px 626px #fff,
    393px 1234px #fff, 881px 113px #fff, 736px 1106px #fff, 659px 303px #fff,
    705px 501px #fff, 1735px 1560px #fff, 1241px 1067px #fff, 1521px 676px #fff,
    147px 563px #fff, 810px 1879px #fff, 1469px 1129px #fff, 1184px 950px #fff,
    1768px 1919px #fff, 610px 1366px #fff, 1779px 1742px #fff, 736px 1576px #fff,
    539px 318px #fff, 929px 762px #fff, 1253px 1195px #fff, 144px 1829px #fff,
    252px 705px #fff, 1436px 1522px #fff, 1884px 32px #fff, 901px 1643px #fff,
    559px 1566px #fff, 1749px 68px #fff, 749px 173px #fff, 883px 240px #fff,
    442px 1246px #fff, 1358px 1567px #fff, 729px 1304px #fff, 1923px 1934px #fff,
    1400px 1395px #fff, 574px 833px #fff, 1847px 750px #fff, 1817px 1688px #fff,
    1232px 790px #fff, 1819px 1426px #fff, 1136px 149px #fff, 1650px 1073px #fff,
    1450px 149px #fff, 490px 568px #fff, 1107px 1481px #fff, 394px 1666px #fff,
    75px 708px #fff, 1821px 794px #fff, 1401px 641px #fff, 1997px 1172px #fff,
    1574px 932px #fff, 135px 288px #fff, 1372px 1073px #fff, 903px 1px #fff,
    1616px 1191px #fff, 1266px 31px #fff, 1615px 1893px #fff, 234px 765px #fff,
    689px 137px #fff, 784px 1463px #fff, 735px 123px #fff, 1558px 1184px #fff,
    916px 483px #fff, 1710px 1668px #fff, 733px 1936px #fff, 533px 9px #fff,
    253px 1376px #fff, 1648px 686px #fff, 700px 1563px #fff, 3px 943px #fff,
    333px 165px #fff, 133px 509px #fff, 1764px 404px #fff, 996px 915px #fff,
    594px 1363px #fff, 75px 726px #fff, 1110px 1967px #fff, 1428px 1817px #fff,
    187px 1685px #fff, 465px 304px #fff, 1976px 103px #fff, 1308px 887px #fff,
    770px 1040px #fff, 752px 429px #fff, 267px 35px #fff, 274px 1252px #fff,
    115px 1035px #fff, 539px 1178px #fff, 286px 741px #fff, 1291px 289px #fff,
    1988px 1717px #fff, 993px 1296px #fff, 519px 358px #fff, 239px 838px #fff,
    1095px 842px #fff, 506px 1961px #fff, 1355px 1885px #fff, 970px 1463px #fff,
    399px 1390px #fff, 188px 1622px #fff, 1685px 1321px #fff, 1754px 588px #fff,
    1622px 720px #fff, 1709px 1781px #fff, 1564px 510px #fff, 1591px 240px #fff,
    1643px 1204px #fff, 1243px 1487px #fff, 99px 1417px #fff, 467px 520px #fff,
    1351px 936px #fff, 1523px 1653px #fff, 1173px 3px #fff, 310px 868px #fff,
    1451px 1094px #fff, 191px 803px #fff, 1463px 1971px #fff, 1098px 303px #fff,
    1589px 219px #fff, 1947px 1288px #fff, 1167px 1131px #fff,
    1787px 1276px #fff, 1833px 1665px #fff, 508px 567px #fff, 1617px 1096px #fff,
    960px 1163px #fff, 1126px 1199px #fff, 838px 568px #fff, 2px 1861px #fff,
    873px 799px #fff, 1337px 1535px #fff, 1967px 482px #fff, 69px 1412px #fff,
    436px 697px #fff, 590px 1332px #fff, 1996px 1720px #fff, 732px 1205px #fff,
    1669px 1027px #fff, 1700px 1309px #fff, 877px 1777px #fff, 1368px 393px #fff,
    1911px 1550px #fff, 985px 1343px #fff, 1340px 559px #fff, 229px 1121px #fff,
    269px 1500px #fff, 1285px 1231px #fff, 896px 1259px #fff, 1156px 1979px #fff,
    920px 1531px #fff, 762px 456px #fff, 1953px 1705px #fff, 740px 1945px #fff,
    1155px 1482px #fff, 1571px 1712px #fff, 670px 984px #fff, 843px 1218px #fff,
    1885px 1231px #fff, 275px 1887px #fff, 1993px 1734px #fff, 1752px 871px #fff,
    1812px 1788px #fff, 126px 1661px #fff, 1733px 590px #fff, 1787px 516px #fff,
    1908px 260px #fff, 500px 308px #fff, 1249px 1432px #fff, 1073px 38px #fff,
    694px 519px #fff, 1435px 293px #fff, 1376px 185px #fff, 879px 1371px #fff,
    1665px 1752px #fff, 500px 355px #fff, 905px 65px #fff, 106px 1505px #fff,
    242px 604px #fff, 277px 1533px #fff, 78px 837px #fff, 41px 1912px #fff,
    1134px 548px #fff, 1725px 1225px #fff, 1227px 750px #fff, 671px 1213px #fff,
    1828px 506px #fff, 932px 1181px #fff, 1271px 1159px #fff, 1048px 591px #fff,
    1333px 497px #fff, 573px 1472px #fff, 1890px 28px #fff, 573px 461px #fff,
    1141px 839px #fff, 1598px 1433px #fff, 406px 1747px #fff, 1716px 201px #fff,
    1406px 1901px #fff, 160px 150px #fff, 1140px 373px #fff, 1719px 921px #fff,
    237px 1014px #fff, 325px 256px #fff, 398px 675px #fff, 1487px 1489px #fff,
    1971px 709px #fff, 1134px 1120px #fff, 1395px 263px #fff, 1251px 1304px #fff,
    917px 1037px #fff, 1998px 743px #fff, 1615px 1334px #fff, 1512px 871px #fff,
    57px 673px #fff, 1089px 15px #fff, 1657px 1868px #fff, 974px 1904px #fff,
    1719px 1780px #fff, 265px 767px #fff, 1168px 961px #fff, 253px 228px #fff;
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 2216px 1519px #fff, 2508px 216px #fff, 2266px 329px #fff,
    2099px 27px #fff, 2340px 381px #fff, 2316px 489px #fff, 2493px 370px #fff,
    2300px 1969px #fff, 2050px 400px #fff, 2150px 705px #fff, 2308px 1122px #fff,
    2050px 400px #fff, 2150px 705px #fff, 2308px 1122px #fff, 2400px 108px #fff,
    2560px 609px #fff, 2200px 27px #fff, 2000px 58px #fff, 2200px 1120px #fff,
    2000px 560px #fff, 2200px 1311px #fff, 426px 937px #fff, 1116px 1519px #fff,
    1308px 216px #fff, 1966px 329px #fff, 201px 27px #fff, 1061px 381px #fff,
    1930px 489px #fff, 565px 370px #fff, 1222px 1969px #fff, 594px 1948px #fff,
    824px 1386px #fff, 1842px 1859px #fff, 1955px 1010px #fff, 201px 1601px #fff,
    1458px 936px #fff, 111px 1249px #fff, 357px 39px #fff, 100px 767px #fff,
    148px 1086px #fff, 500px 918px #fff, 701px 1077px #fff, 1368px 1532px #fff,
    656px 1352px #fff, 291px 1517px #fff, 202px 1221px #fff, 1957px 1608px #fff,
    513px 390px #fff, 1497px 49px #fff, 151px 677px #fff, 89px 1864px #fff,
    1699px 695px #fff, 1977px 566px #fff, 153px 1467px #fff, 316px 1673px #fff,
    1717px 1633px #fff, 1886px 1446px #fff, 820px 409px #fff, 89px 511px #fff,
    1744px 272px #fff, 695px 1788px #fff, 1134px 193px #fff, 513px 1380px #fff,
    1342px 1515px #fff, 1974px 97px #fff, 1780px 990px #fff, 1536px 527px #fff,
    698px 72px #fff, 144px 620px #fff, 651px 300px #fff, 649px 1873px #fff,
    621px 1231px #fff, 1602px 1598px #fff, 1134px 1744px #fff,
    1622px 1070px #fff, 1919px 1220px #fff, 1109px 1465px #fff,
    898px 1098px #fff, 1980px 842px #fff, 1130px 824px #fff, 1768px 1408px #fff,
    1119px 746px #fff, 1548px 1479px #fff, 158px 1117px #fff, 1632px 1897px #fff,
    792px 1379px #fff, 749px 765px #fff, 1137px 516px #fff, 477px 243px #fff,
    1612px 1372px #fff, 1103px 1300px #fff, 111px 1477px #fff, 609px 1196px #fff,
    1293px 528px #fff, 1252px 239px #fff, 904px 1032px #fff, 1241px 381px #fff,
    1424px 1010px #fff, 382px 1517px #fff, 1927px 1666px #fff, 229px 1933px #fff,
    1248px 1475px #fff, 188px 1828px #fff, 557px 537px #fff, 263px 83px #fff,
    352px 266px #fff, 1952px 864px #fff, 1945px 689px #fff, 257px 1692px #fff,
    1846px 437px #fff, 439px 1016px #fff, 1179px 569px #fff, 1236px 1904px #fff,
    869px 1067px #fff, 1665px 82px #fff, 932px 870px #fff, 496px 540px #fff,
    1952px 1552px #fff, 884px 831px #fff, 898px 277px #fff, 140px 1032px #fff,
    320px 482px #fff, 887px 709px #fff, 465px 1686px #fff, 1230px 262px #fff,
    1756px 446px #fff, 464px 1487px #fff, 962px 552px #fff, 1902px 1042px #fff,
    732px 67px #fff, 1741px 620px #fff, 1328px 276px #fff, 1570px 945px #fff,
    890px 84px #fff, 103px 558px #fff, 918px 573px #fff, 1682px 1494px #fff,
    744px 1298px #fff, 1147px 1156px #fff, 1489px 1536px #fff,
    1361px 1313px #fff, 1764px 262px #fff, 38px 1462px #fff, 437px 2000px #fff,
    1978px 200px #fff, 1077px 369px #fff, 1247px 533px #fff, 1274px 1348px #fff,
    768px 1687px #fff, 79px 1319px #fff, 1612px 393px #fff, 708px 621px #fff,
    877px 1099px #fff, 156px 569px #fff, 1818px 1588px #fff, 1737px 813px #fff,
    1321px 27px #fff, 827px 1586px #fff, 1350px 1757px #fff, 1949px 1972px #fff,
    937px 1347px #fff, 1033px 367px #fff, 1355px 421px #fff, 175px 1225px #fff,
    1925px 91px #fff, 835px 365px #fff, 1964px 1132px #fff, 615px 262px #fff,
    239px 1998px #fff, 487px 10px #fff, 1757px 1242px #fff, 1786px 1078px #fff,
    84px 192px #fff, 1555px 405px #fff, 610px 575px #fff, 1928px 1656px #fff,
    10px 466px #fff, 535px 924px #fff, 951px 497px #fff, 1093px 1356px #fff,
    1510px 1649px #fff, 363px 1384px #fff, 1757px 538px #fff, 1266px 174px #fff,
    1367px 83px #fff, 1414px 674px #fff, 302px 955px #fff, 1138px 629px #fff,
    1685px 791px #fff, 663px 365px #fff, 1688px 1940px #fff, 596px 136px #fff,
    1916px 1739px #fff, 883px 1405px #fff, 283px 803px #fff, 339px 1318px #fff,
    79px 1860px #fff, 1157px 550px #fff, 1403px 1934px #fff, 591px 964px #fff,
    1350px 157px #fff, 300px 410px #fff, 320px 136px #fff, 157px 1492px #fff,
    574px 35px #fff, 1726px 1461px #fff, 463px 1292px #fff, 1905px 410px #fff,
    346px 1051px #fff, 1371px 1472px #fff, 752px 453px #fff, 141px 232px #fff,
    180px 849px #fff, 531px 1655px #fff, 1765px 822px #fff, 257px 706px #fff,
    1157px 1484px #fff, 1906px 1011px #fff, 1587px 243px #fff, 1627px 976px #fff,
    1117px 880px #fff;
  -webkit-animation: animStar 350s linear infinite;
  animation: animStar 350s linear infinite;
}
#stars2:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 2416px 1519px #fff, 2508px 216px #fff, 2266px 329px #fff,
    2099px 27px #fff, 2340px 381px #fff, 2316px 489px #fff, 2493px 370px #fff,
    2300px 1969px #fff, 2050px 400px #fff, 2150px 705px #fff, 2308px 1122px #fff,
    2050px 400px #fff, 2150px 705px #fff, 2308px 1122px #fff, 2400px 108px #fff,
    2560px 609px #fff, 2200px 27px #fff, 2000px 58px #fff, 2200px 1120px #fff,
    2000px 560px #fff, 2200px 1311px #fff, 426px 937px #fff, 1116px 1519px #fff,
    1308px 216px #fff, 1966px 329px #fff, 201px 27px #fff, 1061px 381px #fff,
    1930px 489px #fff, 565px 370px #fff, 1222px 1969px #fff, 594px 1948px #fff,
    824px 1386px #fff, 1842px 1859px #fff, 1955px 1010px #fff, 201px 1601px #fff,
    1458px 936px #fff, 111px 1249px #fff, 357px 39px #fff, 100px 767px #fff,
    148px 1086px #fff, 500px 918px #fff, 701px 1077px #fff, 1368px 1532px #fff,
    656px 1352px #fff, 291px 1517px #fff, 202px 1221px #fff, 1957px 1608px #fff,
    513px 390px #fff, 1497px 49px #fff, 151px 677px #fff, 89px 1864px #fff,
    1699px 695px #fff, 1977px 566px #fff, 153px 1467px #fff, 316px 1673px #fff,
    1717px 1633px #fff, 1886px 1446px #fff, 820px 409px #fff, 89px 511px #fff,
    1744px 272px #fff, 695px 1788px #fff, 1134px 193px #fff, 513px 1380px #fff,
    1342px 1515px #fff, 1974px 97px #fff, 1780px 990px #fff, 1536px 527px #fff,
    698px 72px #fff, 144px 620px #fff, 651px 300px #fff, 649px 1873px #fff,
    621px 1231px #fff, 1602px 1598px #fff, 1134px 1744px #fff,
    1622px 1070px #fff, 1919px 1220px #fff, 1109px 1465px #fff,
    898px 1098px #fff, 1980px 842px #fff, 1130px 824px #fff, 1768px 1408px #fff,
    1119px 746px #fff, 1548px 1479px #fff, 158px 1117px #fff, 1632px 1897px #fff,
    792px 1379px #fff, 749px 765px #fff, 1137px 516px #fff, 477px 243px #fff,
    1612px 1372px #fff, 1103px 1300px #fff, 111px 1477px #fff, 609px 1196px #fff,
    1293px 528px #fff, 1252px 239px #fff, 904px 1032px #fff, 1241px 381px #fff,
    1424px 1010px #fff, 382px 1517px #fff, 1927px 1666px #fff, 229px 1933px #fff,
    1248px 1475px #fff, 188px 1828px #fff, 557px 537px #fff, 263px 83px #fff,
    352px 266px #fff, 1952px 864px #fff, 1945px 689px #fff, 257px 1692px #fff,
    1846px 437px #fff, 439px 1016px #fff, 1179px 569px #fff, 1236px 1904px #fff,
    869px 1067px #fff, 1665px 82px #fff, 932px 870px #fff, 496px 540px #fff,
    1952px 1552px #fff, 884px 831px #fff, 898px 277px #fff, 140px 1032px #fff,
    320px 482px #fff, 887px 709px #fff, 465px 1686px #fff, 1230px 262px #fff,
    1756px 446px #fff, 464px 1487px #fff, 962px 552px #fff, 1902px 1042px #fff,
    732px 67px #fff, 1741px 620px #fff, 1328px 276px #fff, 1570px 945px #fff,
    890px 84px #fff, 103px 558px #fff, 918px 573px #fff, 1682px 1494px #fff,
    744px 1298px #fff, 1147px 1156px #fff, 1489px 1536px #fff,
    1361px 1313px #fff, 1764px 262px #fff, 38px 1462px #fff, 437px 2000px #fff,
    1978px 200px #fff, 1077px 369px #fff, 1247px 533px #fff, 1274px 1348px #fff,
    768px 1687px #fff, 79px 1319px #fff, 1612px 393px #fff, 708px 621px #fff,
    877px 1099px #fff, 156px 569px #fff, 1818px 1588px #fff, 1737px 813px #fff,
    1321px 27px #fff, 827px 1586px #fff, 1350px 1757px #fff, 1949px 1972px #fff,
    937px 1347px #fff, 1033px 367px #fff, 1355px 421px #fff, 175px 1225px #fff,
    1925px 91px #fff, 835px 365px #fff, 1964px 1132px #fff, 615px 262px #fff,
    239px 1998px #fff, 487px 10px #fff, 1757px 1242px #fff, 1786px 1078px #fff,
    84px 192px #fff, 1555px 405px #fff, 610px 575px #fff, 1928px 1656px #fff,
    10px 466px #fff, 535px 924px #fff, 951px 497px #fff, 1093px 1356px #fff,
    1510px 1649px #fff, 363px 1384px #fff, 1757px 538px #fff, 1266px 174px #fff,
    1367px 83px #fff, 1414px 674px #fff, 302px 955px #fff, 1138px 629px #fff,
    1685px 791px #fff, 663px 365px #fff, 1688px 1940px #fff, 596px 136px #fff,
    1916px 1739px #fff, 883px 1405px #fff, 283px 803px #fff, 339px 1318px #fff,
    79px 1860px #fff, 1157px 550px #fff, 1403px 1934px #fff, 591px 964px #fff,
    1350px 157px #fff, 300px 410px #fff, 320px 136px #fff, 157px 1492px #fff,
    574px 35px #fff, 1726px 1461px #fff, 463px 1292px #fff, 1905px 410px #fff,
    346px 1051px #fff, 1371px 1472px #fff, 752px 453px #fff, 141px 232px #fff,
    180px 849px #fff, 531px 1655px #fff, 1765px 822px #fff, 257px 706px #fff,
    1157px 1484px #fff, 1906px 1011px #fff, 1587px 243px #fff, 1627px 976px #fff,
    1117px 880px #fff;
}

#stars3 {
  width: 2.5px;
  height: 2.5px;
  background: transparent;
  box-shadow: 2416px 1519px #fff, 2508px 216px #fff, 2266px 329px #fff,
    2099px 27px #fff, 2340px 381px #fff, 2316px 489px #fff, 2493px 370px #fff,
    2300px 1969px #fff, 2050px 400px #fff, 2150px 705px #fff, 2308px 1122px #fff,
    2050px 400px #fff, 2150px 705px #fff, 2308px 1122px #fff, 2400px 108px #fff,
    2560px 609px #fff, 2200px 27px #fff, 2000px 58px #fff, 2200px 1120px #fff,
    2000px 560px #fff, 2200px 1311px #fff, 1501px 1963px #fff, 280px 1956px #fff,
    802px 1784px #fff, 191px 1713px #fff, 416px 1189px #fff, 888px 261px #fff,
    70px 608px #fff, 619px 1940px #fff, 1844px 1310px #fff, 396px 1110px #fff,
    1283px 1919px #fff, 701px 716px #fff, 165px 1562px #fff, 310px 1071px #fff,
    508px 1147px #fff, 644px 657px #fff, 1912px 223px #fff, 72px 1922px #fff,
    1174px 1469px #fff, 76px 1662px #fff, 1737px 490px #fff, 803px 483px #fff,
    1200px 319px #fff, 1183px 432px #fff, 394px 1950px #fff, 424px 446px #fff,
    263px 688px #fff, 457px 1589px #fff, 380px 255px #fff, 775px 959px #fff,
    1088px 9px #fff, 1229px 1247px #fff, 1884px 689px #fff, 1326px 1249px #fff,
    400px 992px #fff, 29px 689px #fff, 1981px 1207px #fff, 1151px 1544px #fff,
    1784px 1666px #fff, 1280px 1862px #fff, 1400px 1286px #fff,
    353px 1068px #fff, 1819px 271px #fff, 1381px 688px #fff, 1405px 796px #fff,
    860px 791px #fff, 436px 62px #fff, 1086px 802px #fff, 935px 1337px #fff,
    1279px 243px #fff, 332px 1340px #fff, 1334px 1557px #fff, 1020px 114px #fff,
    962px 1386px #fff, 663px 1245px #fff, 21px 1726px #fff, 493px 212px #fff,
    895px 1792px #fff, 379px 835px #fff, 493px 1473px #fff, 1734px 957px #fff,
    1334px 1291px #fff, 376px 1984px #fff, 1174px 1588px #fff, 887px 215px #fff,
    791px 444px #fff, 1445px 1041px #fff, 835px 1226px #fff, 1199px 1256px #fff,
    1410px 961px #fff, 607px 415px #fff, 700px 946px #fff, 1722px 1911px #fff,
    1824px 648px #fff, 1867px 123px #fff, 1797px 1749px #fff, 1312px 873px #fff,
    1989px 1649px #fff, 541px 1340px #fff, 257px 1134px #fff, 1750px 891px #fff,
    310px 1011px #fff, 897px 18px #fff, 973px 200px #fff, 1328px 962px #fff,
    809px 410px #fff, 1991px 791px #fff, 1597px 231px #fff, 1232px 287px #fff,
    1200px 1530px #fff, 610px 790px #fff, 14px 1484px #fff, 292px 1008px #fff,
    1420px 1600px #fff, 1714px 1998px #fff, 1099px 1158px #fff,
    1607px 1276px #fff, 1097px 725px #fff, 581px 1457px #fff, 982px 1735px #fff;
  -webkit-animation: animStar 400s linear infinite;
  animation: animStar 400s linear infinite;
}
#stars3:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2.5px;
  height: 2.5px;
  background: transparent;
  box-shadow: 2416px 1519px #fff, 2508px 216px #fff, 2266px 329px #fff,
    2099px 27px #fff, 2340px 381px #fff, 2316px 489px #fff, 2493px 370px #fff,
    2300px 1969px #fff, 2050px 400px #fff, 2150px 705px #fff, 2308px 1122px #fff,
    2050px 400px #fff, 2150px 705px #fff, 2308px 1122px #fff, 2400px 108px #fff,
    2560px 609px #fff, 2200px 27px #fff, 2000px 58px #fff, 2200px 1120px #fff,
    2000px 560px #fff, 2200px 1311px #fff, 1501px 1963px #fff, 280px 1956px #fff,
    802px 1784px #fff, 191px 1713px #fff, 416px 1189px #fff, 888px 261px #fff,
    70px 608px #fff, 619px 1940px #fff, 1844px 1310px #fff, 396px 1110px #fff,
    1283px 1919px #fff, 701px 716px #fff, 165px 1562px #fff, 310px 1071px #fff,
    508px 1147px #fff, 644px 657px #fff, 1912px 223px #fff, 72px 1922px #fff,
    1174px 1469px #fff, 76px 1662px #fff, 1737px 490px #fff, 803px 483px #fff,
    1200px 319px #fff, 1183px 432px #fff, 394px 1950px #fff, 424px 446px #fff,
    263px 688px #fff, 457px 1589px #fff, 380px 255px #fff, 775px 959px #fff,
    1088px 9px #fff, 1229px 1247px #fff, 1884px 689px #fff, 1326px 1249px #fff,
    400px 992px #fff, 29px 689px #fff, 1981px 1207px #fff, 1151px 1544px #fff,
    1784px 1666px #fff, 1280px 1862px #fff, 1400px 1286px #fff,
    353px 1068px #fff, 1819px 271px #fff, 1381px 688px #fff, 1405px 796px #fff,
    860px 791px #fff, 436px 62px #fff, 1086px 802px #fff, 935px 1337px #fff,
    1279px 243px #fff, 332px 1340px #fff, 1334px 1557px #fff, 1020px 114px #fff,
    962px 1386px #fff, 663px 1245px #fff, 21px 1726px #fff, 493px 212px #fff,
    895px 1792px #fff, 379px 835px #fff, 493px 1473px #fff, 1734px 957px #fff,
    1334px 1291px #fff, 376px 1984px #fff, 1174px 1588px #fff, 887px 215px #fff,
    791px 444px #fff, 1445px 1041px #fff, 835px 1226px #fff, 1199px 1256px #fff,
    1410px 961px #fff, 607px 415px #fff, 700px 946px #fff, 1722px 1911px #fff,
    1824px 648px #fff, 1867px 123px #fff, 1797px 1749px #fff, 1312px 873px #fff,
    1989px 1649px #fff, 541px 1340px #fff, 257px 1134px #fff, 1750px 891px #fff,
    310px 1011px #fff, 897px 18px #fff, 973px 200px #fff, 1328px 962px #fff,
    809px 410px #fff, 1991px 791px #fff, 1597px 231px #fff, 1232px 287px #fff,
    1200px 1530px #fff, 610px 790px #fff, 14px 1484px #fff, 292px 1008px #fff,
    1420px 1600px #fff, 1714px 1998px #fff, 1099px 1158px #fff,
    1607px 1276px #fff, 1097px 725px #fff, 581px 1457px #fff, 982px 1735px #fff;
}

@-webkit-keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-1920px);
  }
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-1920px);
  }
}
</style>