import Vue from 'vue'
import App from './App.vue'
import router from './routers'
import './utils/element'
import 'element-ui/lib/theme-chalk/index.css'
Vue.config.productionTip = false
import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad)
// 隐藏 右侧浏览器 滚动条
import VueSmoothScroll from "vue2-smooth-scroll";
Vue.use(VueSmoothScroll)


new Vue({
  el:'#app',
  router,
  render: h => h(App),
}).$mount('#app')

router.afterEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

