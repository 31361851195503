<template>
    <div class="chat-window" v-show="localShowChat" ref="chatWindow">
        <div class="chat-header">
            客服
            <span class="close-btn" @click="closeChat">X</span>
        </div>
        <div class="chat-body">
            <div class="chat-content" style="height: 85%;overflow-y: scroll;">
                <!-- 聊天内容可以在这里呈现 -->
                <div v-for="(item, index) in chatList" :key="index">
                    <div class="chat-item"
                        :class="{ 'chat-item-question': item.isQuestion, 'chat-item-answer': !item.isQuestion }">
                        {{ item.content }}
                    </div>
                </div>
            </div>
            <div class="chat-input" style="display: flex;padding: 5px;">
                <input type="text" placeholder="输入你的问题...." v-model="question" @keyup.enter="sendQuestion" />
                <button @click="sendQuestion">发送</button>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from "axios"
export default {
    data() {
        return {
            localShowChat: this.showChat,
            chatList: [],
            question: "",
        }
    },
    props: ['showChat'],
    watch: {
        showChat(newVal) {
            this.localShowChat = newVal;
            if (!newVal) {
                this.chatList = []
            }
        }
    },
    created() {
        this.chatList = []
    },
    methods: {
        closeChat(){
            this.localShowChat = false
            this.$emit('updateShowChat', false);
        },
        handleClickOutside(event) {
            console.log(this.localShowChat);
            if (this.localShowChat && !this.$refs.chatWindow.contains(event.target)) {
                this.$emit('updateShowChat', false);
            }
        },
        async sendQuestion() {
            await axios.get("https://chatapi.pydance.cn/chat/records/answer/" + this.question).then(res => {
                console.log(res.data)
                this.chatList.push({
                    isQuestion: true,
                    content: this.question
                })
                this.chatList.push({
                    isQuestion: false,
                    content: res.data.data
                })
                this.question = ""
            })
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
}
</script>
  
<style scoped>
.chat-window {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    height: 400px;
    /* border: 1px solid #ccc; */
    background-color: rgba(255, 255, 255, 1);
    z-index: 999999999;
}

.chat-header {
    height: 8%;
    line-height: 30px;
    background-color: #ddd;
    cursor: pointer;
    text-align: center;
}

.close-btn {
    float: right;
    margin-right: 10px;
}

.chat-body {
    padding: 10px;
    height: 90%;
}

.chat-input {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 96%;
}

.chat-input input {
    width: 80%;
}

.chat-input button {
    width: 20%;
}

.chat-item {
    padding: 10px;
    margin: 5px 0;
}

.chat-item-question {
    text-align: left;
    background-color: #f0f0f0;
}

.chat-item-answer {
    text-align: right;
    background-color: #d0f0d0;
}
</style>
  