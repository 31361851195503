<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
  color: transparent;
}
html,
body,
#app {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
}
.el-dialog {
  /* width: 30%; */
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
}
.el-upload {
  margin-left: 25%;
}
.el-upload__tip {
  margin-left: 25%;
}
a {
  text-decoration: none;
  color: #97a6c2;
}
</style>
