<template>
  <div class="cooPartner_wrapper">
    <div class="cooPartner_container">
      <div class="cooPartner_title_wrapper">
        <div class="cooPartner_titleZH">合作伙伴</div>
        <div class="cooPartner_titleEN">COOPERATIVE PARTNER</div>
      </div>
      <div class="cooPartner_list_wrapper">
        <div class="cooPartner_list">
          <div class="cooPartnerImg_wrapper">
            <img
              src="../assets/nvidia.png"
              class="cooPartnerImg"
              style="width: 70%"
            />
          </div>
          <div class="cooPartnerImg_wrapper">
            <img
              src="../assets/aliyun.png"
              class="cooPartnerImg"
              style="width: 70%"
            />
          </div>
          <div class="cooPartnerImg_wrapper">
            <img
              src="../assets/huawei.jpg"
              class="cooPartnerImg"
              style="width: 45%"
            />
          </div>
          <div class="cooPartnerImg_wrapper">
            <img
              src="../assets/yuewen.png"
              class="cooPartnerImg"
              style="width: 125%"
            />
          </div>
          <div class="cooPartnerImg_wrapper">
            <img
              src="../assets/dainziyun.png"
              class="cooPartnerImg"
              style="width: 80%"
            />
          </div>
          <div class="cooPartnerImg_wrapper">
            <img
              src="../assets/xinlang.png"
              class="cooPartnerImg"
              style="width: 75%"
            />
          </div>
          <div class="cooPartnerImg_wrapper">
            <img
              src="../assets/xueyanshe.png"
              class="cooPartnerImg"
              style="width: 45%"
            />
          </div>
          <div class="cooPartnerImg_wrapper">
            <img
              src="../assets/zhongke.png"
              class="cooPartnerImg"
              style="width: 65%"
            />
          </div>
          <div class="cooPartnerImg_wrapper">
            <img
              src="../assets/qudong.png"
              class="cooPartnerImg"
              style="width: 70%"
            />
          </div>
          <div class="cooPartnerImg_wrapper">
            <img
              src="../assets/ruijiake.png"
              class="cooPartnerImg"
              style="width: 80%"
            />
          </div>
        </div>
      </div>
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import bottom from "./Bottom.vue";
export default {
  components: {
    bottom,
  },
};
</script>

<style scoped>
.cooPartner_wrapper {
  width: 100%;
  height: 100vh;
  background: url("../assets/cooPartnerHomeBackground.png") no-repeat;
  background-size: cover;
}
.cooPartner_container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.cooPartner_title_wrapper {
  padding-top: 6%;
  margin-left: 9%;
}
.cooPartner_titleZH {
  font-size: 2.5rem;
  color: #fff;
  font-style: italic;
}
.cooPartner_titleEN {
  font-style: italic;
  font-weight: 600;
  color: #000000;
  font-size: 1.2rem;
  font-style: italic;
  font-family: Arial;
  font-weight: 600;
  cursor: pointer;
  background: linear-gradient(0deg, #10e4e9 0%, #5489fa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.cooPartner_list_wrapper {
  width: 82%;
  margin-left: 9%;
  margin-top: 20px;
  height: 50%;
  padding-bottom: 40px;
  margin-bottom: 2%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
}
.cooPartner_list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.cooPartnerImg_wrapper {
  flex: 0 0 calc(20% - 10px);
  margin: 5px;
  padding: 10px;
  height: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
}
.cooPartnerImg {
  width: 80%;
}
</style>