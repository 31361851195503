<template>
  <div class="buttom_wrapper">
    <div class="bottom_left_wrapper">
      <div class="bottom_left">
        <div class="img_logo_wrapper">
          <img src="../assets/logoColor.png" class="img_logo" />
        </div>
        <div>
          <div class="copyright">
            Copyright 2023 Pydance.All Rights Reserved. 派动科技 版权所有
          </div>
          <div class="filing_nuber">沪ICP备20005750号-2</div>
        </div>
      </div>
    </div>
    <div class="bottom_middle_wrapper">
      <div class="bottom_middle">
        <div class="title">联系我们</div>
        <div class="content_details_wrapper">
          <div class="detail">电话：18221820102</div>
          <div class="detail">邮箱：dingjingyang@pydance.cn</div>
          <div class="detail">
            地址：云南省昆明市西山区滇池路滇池时代广场B座,22楼,2209室
          </div>
        </div>
      </div>
    </div>
    <div class="bottom_right_wrapper">
      <div class="bottom_right">
        <div class="title">关注我们</div>
        <div class="qrcode_img_wrapper">
          <img src="../assets/QRcode.jpg" class="qrcode_img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.buttom_wrapper {
  width: 100%;
  height: 16vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom_left_wrapper {
  width: 38%;
  height: 75%;
  color: #c8cbcf;
  /* background-color: aqua; */
  border-right: 1px solid #3e3e3f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom_left {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.img_logo_wrapper {
  width: 30%;
}

.img_logo {
  width: 100%;
}

.bottom_middle_wrapper {
  width: 35%;
  height: 75%;
  border-right: 1px solid #3e3e3f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom_middle {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.content_details_wrapper {
  color: #c8cbcf;
  margin-top: 25px;
}

.title {
  color: #989a9d;
}

.bottom_right_wrapper {
  width: 27%;
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom_right {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.qrcode_img_wrapper {
  width: 25%;
  margin-top: 10px;
}

.qrcode_img {
  width: 100%;
}
</style>