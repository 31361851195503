import Vue from 'vue'
import { Message,Cascader,Dialog,Button,Form,FormItem,Submenu,Input,Upload,DatePicker,MessageBox,Loading,Menu,MenuItem,Pagination  } from 'element-ui'

Vue.use(Dialog)
Vue.use(Button)
Vue.use(Pagination)
Vue.use(Submenu)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Upload)
Vue.use(DatePicker)
Vue.use(Cascader)
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm