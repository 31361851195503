<template>
  <div class="swiper_wrapper">
    <div class="swiper">
      <swiper
        :options="swiperOption"
        class="swiper1"
      >
        <swiper-slide>
          <img
            src="../assets/instance/wall.png"
            class="swiperImg"
            @click="getDetail(0)"
          />
          <div class="imgName">墙体缺陷检测</div>
        </swiper-slide>
        <swiper-slide>
          <img
            src="../assets/instance/game.png"
            class="swiperImg"
            @click="getDetail(1)"
          />
          <div class="imgName">游戏舆情分析及报告</div>
        </swiper-slide>
        <swiper-slide>
          <img
            src="../assets/instance/factory.png"
            class="swiperImg"
            @click="getDetail(2)"
          />
          <div class="imgName">工厂安全行为检测</div>
        </swiper-slide>
        <swiper-slide>
          <img
            src="../assets/instance/player.png"
            class="swiperImg"
            @click="getDetail(3)"
          />
          <div class="imgName">滑雪者图像检索</div>
        </swiper-slide>
        <swiper-slide>
          <img
            src="../assets/instance/government.png"
            class="swiperImg"
            @click="getDetail(4)"
          />
          <div class="imgName">政企信息分析</div>
        </swiper-slide>
      </swiper>
      <!-- <div class="swiper-button-prev" slot="button-prev"></div> -->
      <!-- 箭头左 -->
      <div class="swiper-button-next" slot="button-next"></div>
      <!-- 箭头右 -->
    </div>
  </div>
</template>
  
  <script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "Main",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        //设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        slidesPerView: "auto",
        loopedSlides: 5,
        spaceBetween: 30,
        slidesPerView: 5, // 一屏显示的slide个数
        centeredSlides: true, // 居中的slide是否标记为active，默认是最左active,这样样式即可生效
        slideToClickedSlide: true, // 点击的slide会居中
        loop: true, // 循环播放, 可有无限滚动效果，初始加载即是滚动后的效果
        // on: {
        //   // 该方法中的this都指代swiper本身
        //   tap: function () {
        //     console.log("点击的位置", this.activeIndex);
        //   },
        // },
      },
    };
  },
  // created() {
  //   console.log(window.innerHeight);
  // },
  methods: {
    test(e) {
      // 默认会$event对象
      console.log(11, e);
    },
    getDetail(index) {
      // this.$nextTick(()=>{
      console.log(index);
      this.$router.push({
        path: "/userInstance",
        query: {
          index: index,
        },
      });
      // })
    },
  },
};
</script>
  
  <style scoped>
.swiper_wrapper {
  width: 100%;
  height: 130vh;
}
.swiper {
  width: 100%;
  height: 53.6%;
  position: relative;
}
.swiper1 {
  width: 100%;
  height: 100%;
  position: relative;
}
.swiper-container {
  width: 81.25%;
  height: 100%;
  position: relative;
}
.swiper-slide {
  text-align: center;
  font-size: 2rem;
  color: aliceblue;
  /* background: #fff; */
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.4);
  z-index: -1;
  /* width: 16.25% !important;
    height: 100% !important;
    overflow: hidden; */
}
.imgName {
  margin-top: 2rem;
  font-weight: 400;
  color: #ffffff;
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(0.8);
  /* width: 48.75% !important;
    height: 100% !important; */
  z-index: 99;
}
.swiper-button-prev,
.swiper-button-next {
  /* top: 410vh; */
  position: absolute;
  color: rgba(225, 225, 225, 0.2);
  /* background: rgba(225, 225, 225, 0.2); */
}
.swiper-button-prev {
  left: 5%;
  right: auto;
}
.swiper-button-next {
  right: 5%;
  left: auto;
}
</style>