import {request} from "@/requests/index"

export const submitMsg = (val) => {
    return request({
      url: "/webContact/contact",
      method: "post",
      data: val
    });
  };

export const getPositionList = (val) => {
    return request({
      url: "/recruitment/search",
      method: "post",
      data: val
    });
  };