<template>
  <div class="navigator_wrapper">
    <div class="icon_container">
      <img src="../assets/logo.png" class="icon_img1" />
      <!-- <div class="gradient"></div> -->
    </div>
    <div class="nav1">
      <div class="nav2">
        <!-- <router-link
          class="nav-item1"
          :to="{ path: item.path }"
          v-for="(item, index) in navList"
          :key="index"
          active-class="active"
        >
          {{ item.name }}
        </router-link> -->



        <el-menu
          style="width: 80%;display: flex;align-items: center;justify-content: space-around;height: 300px;z-index: 999999999999999;"
          :default-active="activeIndex2" class="el-menu-demo" background-color="transparent" text-color="#9fadc9"
          active-text-color="#fff">
          <el-menu-item index="/home" style="font-size: 20px;"><router-link to="/home">首页</router-link></el-menu-item>
          <el-menu-item index="/solution" style="font-size: 20px;"><router-link
              to="/solution">解决方案</router-link></el-menu-item>
          <el-menu-item index="/abouy" style="font-size: 20px;"><router-link to="/technology">派动技术</router-link></el-menu-item>
          <el-menu-item index="/abouy" style="font-size: 20px;"><router-link to="/about">关于我们</router-link></el-menu-item>
          <!-- <el-menu-item index="/join" style="font-size: 20px;"><router-link to="/join">加入我们</router-link></el-menu-item> -->
          <!-- <el-menu-item index="3" style="font-size: 20px;">产品</el-menu-item> -->
          <!-- <el-submenu index="products" style="font-size: 20px;">
            <template slot="title">产品</template> -->
          <!-- <el-menu-item><a href="http://chat.pydance.cn/#/login" target="_blank"
              style="font-size: 18px;">派动云问答</a></el-menu-item> -->
        </el-menu>

      </div>
    </div>
    <!-- <div class="right_nav_wrapper">
      <div class="telephone_wrapper">
        <i class="el-icon-phone" style="font-size: 25px;color: white;"></i>
        <div class="telephone">15812120562</div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex2: '1',
      navList: [
        {
          name: "首页",
          path: "/home",
        },
        {
          name: "解决方案",
          path: "/solution",
        },
        {
          name: "派动技术",
          path: "/technology",
        },
        {
          name: "关于我们",
          path: "/about",
        },
        // {
        //   name: "加入我们",
        //   path: "/join",
        // },
      ],
    };
  },
};
</script>

<style scoped>
.navigator_wrapper {
  display: flex;
  width: 100%;
  height: 5.8vw;
  position: fixed;
  top: 0;
  border-bottom: 1px solid rgba(225, 225, 225, 0.2);
}

.icon_container {
  width: 15%;
  height: 100%;
}

.gradient {
  height: 2px;
  width: 100%;
  margin-top: 9.5%;
  background: linear-gradient(to right, #488c95, #4a6a93);
}

.icon_img1 {
  margin-top: 5%;
  margin-left: 10%;
  height: 58%;
}

.nav1 {
  width: 70%;
  height: 100%;
}

.nav2 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.4rem;
}

.el-menu {
  border-right: none;
}

.el-menu-item:hover {
  background-color: transparent !important;
  color: #fff !important;
}

.nav-item1 {
  color: #9fadc9;
  text-decoration: none;
}

.right_nav_wrapper {
  width: 15%;
  height: 100%;
  display: flex;
}

.telephone_wrapper {
  width: 21%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.icon_phone {
  width: 25%;
  height: 18%;
}

.telephone {
  width: 75%;
  font-size: 1.4rem;
  color: #ffffff;
}

.language_switching {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: #ffffff;
}

.language_switching_wrapper {
  width: 97px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 9%;
}

.zh {
  width: 50px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.EN {
  width: 50px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active {
  color: #ffffff;
}

::v-deep .el-menu-item.is-active {
  background-color: transparent;
  color: #fff;
}

::v-deep .el-menu-item:not(.is-active) {
  background-color: transparent;
  color: #97a6c2;
}

.router-link-active {
  color: #fff;
}

:deep(.el-submenu__title) {
  font-size: 20px !important;
  ;
}

:deep(.el-submenu__title:hover) {
  background-color: transparent !important;
}

:deep(.el-menu-item) {
  font-size: 20px;
}
</style>