<template>
  <div>
    <div class="main-contain">
      <div class="back"></div>
      <navigatorBar style="background: rgba(0,0,0,0.5);" />
      <div class="techTitle_wrapper">
        <div class="techZHTitle">关于派动</div>
        <div class="techENTitle">ABOUT US</div>
      </div>
      <div class="slogan_wrapper">
        <div class="slogan">
          <div>人工智能 创造价值</div>
        </div>
      </div>
      <div class="content-area">
        <!-- <div class="idea-content">
          <div class="idea_left_wrapper">
            <div class="idea-text">
              派动科技是以人工智能和数据科学为核心的科技创新型企业，公司的理念是“人工智能，创造价值”，公司致力于用领先的数据科学技术和先进的人工智能算法，在工业制造、金融、智慧城市和生命科学等领域创造出真正有价值的智能产品，立志打造成世界顶尖的人工智能企业。目前，我们主要专注于计算机视觉、自然语言处理和知识图谱领域，涵盖了智慧城市、智慧文旅、生命科学、智能制造等行业，核心技术包括物体检测、关键点定位、身份验证、遥感图像解译、文字识别、数据挖掘与分析、智能绘画、医学图像分析、数字人和内容增强等。
            </div>
          </div>
        </div> -->
        <div class="company_details_wrapper">
          <div class="company_introduce_wrappe">
            <div class="company_intrduce_title">
              <div class="company_intrduce_titleZH">公司介绍</div>
              <div class="company_intrduce_titleEN">COMPANY PROFILE</div>
            </div>
            <div class="company_introduce_des">
              派动科技是以人工智能和数据科学为核心的科技创新型企业，公司的理念是“人工智能，创造价值”，公司致力于用领先的数据科学技术和先进的人工智能算法，在工业制造、金融、智慧城市和生命科学等领域创造出真正有价值的智能产品，立志打造成世界顶尖的人工智能企业。目前，我们主要专注于计算机视觉、自然语言处理和知识图谱领域，涵盖了智慧城市、智慧文旅、生命科学、智能制造等行业，核心技术包括物体检测、关键点定位、身份验证、遥感图像解译、文字识别、数据挖掘与分析、智能绘画、医学图像分析、数字人和内容增强等。
            </div>
          </div>
          <div class="company_img_wrappe">
            <img src="../assets/conpanyIntroduce.jpg" style="width:100%;">
          </div>
        </div>
      </div>
    </div>
    <honor />
    <join />
    <bottom />
  </div>
</template>

<script>
import navigatorBar from "@/components/NavigatorBar";
import bottom from "@/components/Bottom";
import chat from "@/components/Chat.vue";
import join from "@/views/Join.vue"
import honor from "@/components/Honor.vue"

export default {
  name: "AboutUs",
  data() {
    return {
      showChat: false,
    };
  },
  components: {
    navigatorBar,
    bottom,
    chat,
    join,
    honor
  },
  methods: {
    chatServer() {
      this.showChat = !this.showChat;
      console.log(this.showChat);
    },
    updateShowChat(value) {
      this.showChat = value;
    },
  },
};
</script>

<style scoped>
.main-contain {
  font-family: "Microsoft YaHei", serif;
  /* height: 45vw; */
  width: 100%;
}

.back {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: url("../assets/aboutBackground.jpg") no-repeat;
  background-size: 100% 100%;
}

.techTitle_wrapper {
  margin-top: 7.554vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.techZHTitle {
  font-size: 2.5rem;
  font-family: Microsoft YaHei;
  font-weight: 600;
  color: #ffffff;
  font-style: italic;
}

.techENTitle {
  font-size: 1.2rem;
  font-style: italic;
  font-family: Arial;
  font-weight: 600;
  margin-top: 0.675vw;
  color: #000000;
  cursor: pointer;
  background: linear-gradient(0deg, #10e4e9 0%, #5489fa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.slogan_wrapper {
  width: 100%;
}

.slogan {
  margin-top: 25px;
  width: 100%;
  height: 15vh;
  color: #ffffff;
  font-size: 2.6rem;
  font-style: italic;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.content-area {
  width: 80%;
  /* height: 100%; */
  margin: auto;
  margin-top: 70px;
  margin-bottom: 150px;
  /*border: white solid;*/
}

.company_details_wrapper {
  width: 100%;
  display: flex;
}

.company_introduce_wrappe {
  width: 40%;
  margin-top: 5%;
}

.company_intrduce_title {
  padding-bottom: 5px;
  width: 40%;
  margin-bottom: 30px;
  border-bottom: 1px solid #4e5053;
}

.company_intrduce_titleZH {
  font-size: 1.9rem;
  font-family: Microsoft YaHei;
  font-weight: 600;
  color: #ffffff;
  font-style: italic;
}

.company_intrduce_titleEN {
  font-size: 1rem;
  font-style: italic;
  font-family: Arial;
  font-weight: 600;
  color: #000000;
  cursor: pointer;
  background: linear-gradient(0deg, #10e4e9 0%, #5489fa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.company_img_wrappe {
  width: 50%;
  margin-left: 100px;
}

.company_introduce_des {
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 180%;
  letter-spacing: 2px;
}

.company_introduce_des .breadcrumb {
  display: flex;
  flex-wrap: nowrap;
  color: #ffffff;
  font-size: 1.2rem;
  width: 100%;
  height: 1.5%;
  margin-top: 1.96875vw;
}

.breadcrumb-span {
  width: 20%;
}

.bread-divider {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 84%;
  background-color: #506185;
  height: 1px;
  margin-top: 0.9%;
  margin-left: 2%;
}

.idea-title {
  color: #10e4e9;
  font-size: 2rem;
  margin-top: 4.5vw;
}

.idea-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.125;
  /* height: 19.18125vw; */
}

.idea_left_wrapper {
  width: 65%;
  /* height: 5.34375vw; */
}

.idea-text {
  color: #ffffff;
  font-size: 1.8rem;
  line-height: 210%;
  letter-spacing: 3px;
  text-indent: 1.5cm;
  width: 100%;
  margin: 50px 0;
}

.idea-pic1 {
  height: 16.25625vw;
  width: 100%;
  margin-top: 23%;
}

.idea-pic2 {
  height: 20.1375vw;
  width: 52.1%;
  margin-left: 5%;
}

.goal-title {
  margin-top: 1.40625vw;
  color: #10e4e9;
  font-size: 2rem;
}

.goal-content {
  width: 100%;
  height: 19.18125vw;
  margin-top: 1.40625vw;
}

.goal-text {
  color: #ffffff;
  font-size: 1.2rem;
}

.goal-pic1 {
  height: 17.71875vw;
  width: 50%;
  margin-top: 1%;
}

.goal-pic2 {
  height: 17.71875vw;
  width: 45%;
  margin-top: 1%;
  margin-left: 5%;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  margin-top: 1.40625vw;
}</style>
